import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import {Layout} from '@components/layout/layout'
import { H1, H3, Paragph } from "@components/layout/style"
import { ListDisc, MainContainer, Question,Response, RightContainer, StyledHero } from "../../components/oferta.style"


const ChirurgiaStomatologiczna:React.FC = ({data}) => {
  return (<Layout data={data} prop={{
      canonicalUrl: 'https://www.mediroot.pl/oferta/stomatologia-chirurgiczna/',
      title: 'Stomatologia chirurgiczna | Mediroot',
      description: 'Mediroot ➤ Stomatolog Wągrowiec - Stomatologia zachowawcza ✔ Stomatologia dziecięca ✔ Ortodoncja ✔ Protetyka stomatologiczna ✔ Zapoznaj się z ofertą i zadzwoń już dziś! ☎'
  }}>
    <StyledHero>
      <StaticImage
        src="../../assets/images/hero.jpg"
        placeholder="blurred"
        alt="Hero image"
        layout="fullWidth"/>
    </StyledHero>
    <MainContainer>
      <RightContainer>
        <H1>
          CHIRURGIA STOMATOLOGICZNA
        </H1>

        <Paragph>

Dziedzina stomatologii zajmująca się leczeniem operacyjnym jamy ustnej i okolic przyległych. Jest podspecjalnością chirurgii. Częste schorzenia to zębopochodne stany zapalne, a w szczególności ropnie oraz okołowierzchołkowe, przewlekłe stany zapalne.
Chirurgia stomatologiczna to nie tylko zabiegi usuwania zębów. W ramach tej specjalności wykonywany jest cały szereg zabiegów w obszarze obejmującym jamę ustną, w tym zabiegi wspomagające inne dziedziny stomatologii, np. endodoncję – resekcje wierzchołka korzenia, periodontologię – chirurgia przyzębia, protetykę – chirurgia przedprotetyczna, czy wreszcie najnowocześniejszy dział – implantologię. Chirurgia stomatologiczna dotyczy wszystkich krwawych zabiegów, które wykonuje się w jamie ustnej w celu likwidacji stanów zapalanych i poprawy warunków anatomicznych kości szczęk i tkanek miękkich.

        </Paragph>

        <Paragph>
        Sukces postępowania leczniczego i dobór optymalnej strategii postępowania zawsze idą w parze z
dbałością o komfort psychiczny pacjenta. Leczenie chirurgiczne wywołuje u pacjentów obawy
przed operacją, powikłaniami i bólem. Dlatego też każdorazowo pacjent jest przygotowywany do
zabiegu. Lekarz tłumaczy dokładnie cały plan leczenia, poszczególne etapy, i możliwe następstwa.
Wszystkie zabiegi chirurgiczne wykonywane są w znieczuleniu przy zachowaniu aseptyczności pola operacyjnego. Po leczeniu pacjent jest także otaczany szczególną
opieką tak, by cały proces zakończył się sukcesem, a pacjent możliwie szybko doszedł do zdrowia.
        </Paragph>

        <Paragph>
        Do najczęściej wykonywanych zabiegów należą:
        </Paragph>

        <ListDisc>
          <li>
            ekstrakcje (usuwanie zębów) również zębów zatrzymanych i niewyrzniętych
          </li>
          <li>
            odsłanianie zębów zatrzymanych w łuku
          </li>
          <li>
            plastyka połączeń z zatoką szczękową
          </li>
          <li>
            ekstrakcje zębów mądrości
          </li>
          <li>
            resekcja wierzchołka korzenia
          </li>
          <li>
            przygotowanie jamy ustnej do leczenia protetycznego
          </li>
          <li>
            podcinanie wędzidełek
          </li>
          <li>
            operacyjne usuwanie zębów zatrzymanych
          </li>
          <li>
            hemisekcje
          </li>
          <li>
            kliniczne wydłużanie koron
          </li>
          <li>
            wszczepianie implantów
          </li>
          <li>
            podnoszenie dna zatoki
          </li>
        </ListDisc>

        <H3>
          Ekstrakcje zębów
        </H3>
        <Paragph>
          Czasy gdy usuwanie zębów kojarzone było z ogromnym bólem i stresem minęły już bezpowrotnie.
          Obecnie wszystkie zabiegi  przeprowadzane są  w doskonałym znieczuleniu miejscowym.
          Dzięki temu nieprzyjemne odczucia podczas zabiegu zredukowane są do minimum,
          a pacjent po zaopatrzeniu rany po usuniętym zębie gotowy jest do normalnego funkcjonowania.
          Ekstrakcje zębów przeprowadza się z następujących wskazań:
        </Paragph>
        <ListDisc>
          <li>
            znaczne zniszczenie próchnicowe zęba wymagające leczenia kanałowego przy braku możliwości przeprowadzenia takiego leczenia
          </li>
          <li>
            utrudnione wyrzynanie powodujące powstawanie uciążliwych stanów zapalnych w okolicy zęba ósmego
          </li>
          <li>
            zatrzymanie zęba ósmego – gdy nie ma szans na jego prawidłowe wyrznięcie z powodu zbyt małej ilości miejsca w łuku zębowym, zęby takie usuwa się w celu uniknięcia groźnych powikłań jak np. tworzenie się torbieli zawiązkowej
          </li>
          <li>
            ze wskazań ortodontycznych
          </li>
        </ListDisc>

        <H3>
          PYTANIA
        </H3>
        <Paragph style={{textIndent:0}}>
          <Question>
            Jak długo utrzymuje się bolesność po usunięciu zęba?
          </Question>
          <Response>
            Ból po ekstrakcji z reguły zanika w ciągu kilku godzin, na ten okres lekarz może  przepisać środki przeciwbólowe.
          </Response>
          <Question>
            Czy jest możliwość natychmiastowego uzupełnienia braków po usuniętych zębach?
          </Question>
          <Response>
            Tak,  dostępna dzięki zastosowaniu protez natychmiastowych oddawanych pacjentowi bezpośrednio po ekstrakcji zęba.
          </Response>
          <Question>
            Czy zawsze podaje się antybiotyk po zabiegu?
          </Question>
          <Response>
            Nie, antybiotyk podaje się tylko w przypadkach tego wymagających.
          </Response>
          <Question>
            Czy palenie papierosów pogarsza gojenie rany?
          </Question>
          <Response>
            Tak, u palaczy znacznie zwiększa się ryzyko powikłanego gojenia rany po zabiegowej, dlatego też przez okres 24 godzin po zabiegu należy unikać palenia tytoniu, w przypadku bolesnego.
          </Response>
        </Paragph>

        <H3>
          Podniesienie dna zatoki szczękowej (SINUS LIFT)
        </H3>
        <Paragph>
            Jest to zabieg chirurgiczny polegający na wprowadzeniu materiału kościozastępczego pod błonę śluzową wyściełającą zatokę szczękową, w celu zwiększenia ilości kości, przed planowanym zabiegiem implantologicznym, w trudnych warunkach anatomicznych, w bocznych odcinkach szczęki.
            Trudne warunki do wprowadzania implantów w bocznym odcinku wyrostka zębodołowego kości szczęki występują stosunkowo często. Zjawisko to dotyczy głównie szczęki bezzębnej, której pneumatyzacja wzrasta stopniowo w trakcie życia i w miarę utraty zębów. Jedną z technik operacyjnych umożliwiających stosowanie implantów w tych właśnie okolicach jest podnoszenie dna zatoki szczękowej , Zabieg polega na wprowadzaniu kości autogennej, materiałów kościotwórczych w przestrzeń powstałą między dnem zachyłka zębodołowego zatoki szczękowej a uniesioną błoną śluzową wyściełającą ten zachyłek.
        </Paragph>

        <H3>
        Implantologia
        </H3>
        <Paragph>
        Czy wiedzieli Państwo, że zdrowie jamy ustnej ma niezwykle duży wpływ na ogólny stan zdrowia?
        Zastąpienie braków zębowych implantami pozwoli Wam cieszyć się życiem tak, jakbyście nigdy nie mieli braków w uzębieniu. Po zakończeniu leczenia każdy pacjent może bez przeszkód gryźć, żuć, mówić i cieszyć się życiem towarzyskim nie doświadczając negatywnych uczuć towarzyszących posiadaniu braków zębowych.
        Implanty pomagają Państwu zminimalizować ilość utraconej kości, która zanika w wyniku posiadania braków zębowych. Zachowanie kości może zapobiec utracie zdrowych zębów oraz pozwoli zachować naturalne rysy twarzy. W porównaniu z innymi możliwościami leczenia braków zębowych takimi jak mosty, protezy częściowe lub całkowite, implant i jego korona zapewnia długotrwałe rozwiązania protetyczne, redukując potrzebę szlifowania sąsiednich, naturalnych zębów oraz przywracają funkcje i estetykę naturalnego uzębienia
        Mosty protetyczne na implantach
        Leczenie takie umożliwia uzupełnienie większych ilościowo braków zębowych za pomocą mostu w sytuacji, gdy brak jest naturalnych zębów filarowych. Po okresie 3 do 6 miesięcy przeprowadza się identyczne procedury jak w przypadku koron na implantach :
        </Paragph>
        <ListDisc>
          <li>
            I wizyta: lekarz stomatolog obnaża implanty i odkręca śruby zamykające wkręcone w implanty, następnie wkręca śruby gojące.
          </li>
          <li>
            II wizyta: po upływie około 14 dni lekarz odkręca śruby gojące i pobiera wycisk.
          </li>
          <li>
            III wizyta: laboratoryjny Technik wykonuje most
          </li>
          <li>
          IV wizyta: Lekarz stomatolog przykręca łączniki protetyczne do implantu  oraz cementuje/przykręca  most w jamie ustnej
          </li>
        </ListDisc>

        <H3>
          Protezy ruchome na implantach
        </H3>
        <Paragph>
          Znane są różne rodzaje protez ruchomych na implantach. Na przykład całkowite i częściowe. Mocowane są różnymi systemami retencyjnymi. Najczęściej wykonuje się protezy całkowite poprawiając ich retencję i stabilizację. Proteza całkowita umieszczona na zaczepach kulowych na implantach wykazuje znacznie lepsze umocowanie i brak ruchomości niż zwykła proteza całkowita. Dzięki temu rozwiązanie jest bardzo komfortową i coraz częściej stosowaną alternatywą dla Pacjentów z bezzębiem. W przypadku protezy dolnej zwykle wystarczające jest wszczepienie 2 implantów. Etapy postępowania kliniczno-laboratoryjnego
        </Paragph>
        <ListDisc>
          <li>
            I wizyta: po zabiegu wszczepienia implantów lekarz stomatolog nacina błonę śluzową i odkręca śruby zamykające znajdujące się w implantach, następnie wkręca śruby gojące
          </li>
          <li>
            II wizyta: po upływie około 14 dni lekarz odkręca śruby gojące i przykręca do implantu filary kulkowe, a następnie wykonuje wyciski.
          </li>
          <li>
            III wizyta: laboratoryjny Technik wykonuje poszczególne etapy pracy związanej z wyprodukowaniem nowej protezy (overdenture)
          </li>
          <li>
            IV wizyta: Lekarz oddaje protezę pacjentowi
          </li>
        </ListDisc>

      </RightContainer>
    </MainContainer>


  </Layout>);
};

export default ChirurgiaStomatologiczna;


export const query = graphql`
  query {
    logo: file(relativePath: { eq: "images/logo3.png" }) {
      childImageSharp {
        fluid( maxHeight: 92) {
          src
          sizes
          srcSet
          srcWebp
          base64
          aspectRatio
        }
      }
    }
  }
  `
